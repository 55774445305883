import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const NavItems = ({ navbarItems, toggleMobileNav, openNav }) => {
  if (navbarItems == null) navbarItems = false;

  // When tabbing through the menu we want it to close when
  // focus is lost on the last item
  const handleLastTab = (e, lastLink) => {
    // Only interested if it's the last link
    if (lastLink) {
      if (!e.shiftKey && e.keyCode === 9) {
        // Not interested if tabbing backwards
        // i.e. shift is held
        toggleMobileNav();
      }
    }
  };

  // If the last link in the nav GAINS keyboard focus
  // Then we want the menu to open
  // To open the menu when someone is tabbing backwards
  const handleFocus = (lastLink) => {
    if (lastLink) {
      openNav();
    }
  };

  return (
    <ul>
      {navbarItems &&
        navbarItems.map((navItem, index) => {
          let to = "";

          if (navItem.link != null) to = navItem.link;

          if (navItem.route != null) to = navItem.route;

          if (navItem.landingPageRoute != null) to = `/${navItem.landingPageRoute.slug.current}`;

          if (navItem.landingPageRoute && navItem.landingPageRoute.requiresAuthentication)
            to = "/auth" + to;

          const externalLink = to === navItem.link;

          // Work out if this is the last link
          let lastLink = false;
          if (navbarItems.length === index + 1) {
            lastLink = true;
          }

          return (
            /*
             * If the link is external we use a normal anchor tag, if it is not, we use Gatsby Link.
             */
            <li key={index}>
              {externalLink ? (
                <a
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                  onKeyDown={(e) => {
                    handleLastTab(e, lastLink);
                  }}
                  onFocus={() => {
                    handleFocus(lastLink);
                  }}
                >
                  {navItem.title}
                </a>
              ) : (
                <Link
                  to={to}
                  onKeyDown={(e) => {
                    handleLastTab(e, lastLink);
                  }}
                  onFocus={() => {
                    handleFocus(lastLink);
                  }}
                >
                  {navItem.title}
                </Link>
              )}
            </li>
          );
        })}
    </ul>
  );
};

NavItems.propTypes = {
  navMenuItems: PropTypes.array,
  toggleMobileNav: PropTypes.func,
  openNav: PropTypes.func,
};

export default NavItems;
