import React from "react";
import BasePortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

const PortableText = ({ blocks, clientConfig }) => {
  if (clientConfig == null) {
    clientConfig = {};
    clientConfig.sanity = {};
  }

  const serializersObject = serializers(clientConfig);

  return (
    <BasePortableText blocks={blocks} serializers={serializersObject} {...clientConfig.sanity} />
  );
};

export default PortableText;
