import React from "react";
import PropTypes from "prop-types";

export const TwitterTimeline = ({ twitterUrl, timelineTitle }) => {
  // allows inline scripts to run on client-side pages fetched by ajax
  if (typeof document !== `undefined`) {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
    return (
      <div id="twitterTimeline">
        <a className="twitter-timeline" href={twitterUrl} data-height="650">
          {timelineTitle}
        </a>
      </div>
    );
  } else {
    // Otherwise, since document isn't available at build time
    // Render normally
    return (
      <div id="twitterTimeline">
        <a className="twitter-timeline" href={twitterUrl} data-height="650">
          {timelineTitle}
        </a>
        <script async src="https://platform.twitter.com/widgets.js"></script>
      </div>
    );
  }
};

TwitterTimeline.propTypes = {
  twitterUrl: PropTypes.string,
  timelineTitle: PropTypes.string,
};
