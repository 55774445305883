import PropTypes from "prop-types";
import React from "react";

import MastHead from "./masthead";
import Banner from "./banner";

const Header = ({
  socials,
  siteTitle,
  headerImage,
  siteLogo,
  navbarList,
  mastheadConfig,
  awsConfig,
  clientConfig,
}) => {
  return (
    <>
      <MastHead
        socials={socials}
        mobileNavList={navbarList}
        awsConfig={awsConfig}
        mastheadConfig={mastheadConfig}
        clientConfig={clientConfig}
      />
      <Banner
        siteTitle={siteTitle}
        headerImage={headerImage}
        siteLogo={siteLogo}
        clientConfig={clientConfig}
      />
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  headerImage: PropTypes.object,
  siteLogo: PropTypes.object,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
