import React from "react";

import NavItems from "./navItems";

const MobileNav = ({ navbarList, auth, signOut, toggleMobileNav, openNav }) => {
  // Signout button, then navigation menu
  return (
    <>
      {auth ? (
        <aside className="login-status-long">
          <p>You are currently logged in as {auth.attributes.email}.</p>
          <div className="logged-in-options">
            <button onClick={signOut} className="sign-out-button">
              Log out
            </button>{" "}
            or{" "}
            <a className="change-password-link" href="/login/reset">
              change your password
            </a>
          </div>
        </aside>
      ) : (
        ""
      )}
      <nav>
        <NavItems navbarItems={navbarList} toggleMobileNav={toggleMobileNav} openNav={openNav} />
      </nav>
    </>
  );
};

export default MobileNav;
