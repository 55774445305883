import React, { Component } from "react";

import { Header } from "../";
import { FooterBlock } from "../";
import { Navbar } from "../";
import { Sidebar } from "../";
import "../components/app.scss";

export default class Layout extends Component {
  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      siteTitle,
      navMenuItems,
      socials,
      headerImage,
      siteLogo,
      sidebarData,
      recentBlogs,
      footerData,
      displaySidebar,
      awsConfig,
      mastheadConfig,
      clientConfig,
    } = this.props;
    return (
      <>
        <Header
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          socials={socials}
          siteLogo={siteLogo}
          headerImage={headerImage}
          navbarList={navMenuItems}
          awsConfig={awsConfig}
          mastheadConfig={mastheadConfig}
          clientConfig={clientConfig}
        />
        <Navbar navbarList={navMenuItems} />
        <main>
          <div className="container mx-auto">
            <div className="flex flex-wrap mx-3">
              <div
                className={
                  displaySidebar ? "w-full sm:w-2/3 px-3" : "w-full lg:w-11/12 mx-auto px-3"
                }
              >
                {children}
              </div>

              {displaySidebar && (
                <div className="w-full sm:w-1/3 px-3">
                  <Sidebar sidebarData={sidebarData} recentBlogs={recentBlogs} />
                </div>
              )}
            </div>
          </div>
        </main>
        <FooterBlock footerData={footerData} />
      </>
    );
  }
}
