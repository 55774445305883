import React from "react";

import NavItems from "./navItems";

const Navbar = ({ navbarList }) => {
  return (
    <div className="container flex mx-auto">
      <div className="w-full">
        <nav className="desktop-nav">
          <NavItems navbarItems={navbarList} />
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
