import React from "react";

const SoundcloudEmbed = ({ title, soundcloudEmbedCode }) => {
  // Example:  <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/713312815&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/againstmeofficial" title="Against Me!" target="_blank" style="color: #cccccc; text-decoration: none;">Against Me!</a> · <a href="https://soundcloud.com/againstmeofficial/pints-of-guinness-make-you-3" title="Pints of Guinness Make You Strong" target="_blank" style="color: #cccccc; text-decoration: none;">Pints of Guinness Make You Strong</a></div>
  function createIFrame() {
    return { __html: soundcloudEmbedCode };
  }
  // dangerouslySetInnerHTML doesn't work with script tags so we're safe on that front
  return (
    <div className="soundcloud-wrapper">
      {soundcloudEmbedCode && <div dangerouslySetInnerHTML={createIFrame()}></div>}
    </div>
  );
};

export default SoundcloudEmbed;
