import React from "react";
import { Link } from "gatsby";

const MenuList = (props) => {
  return (
    <div>
      {props.title != null ? <h4 className="menuTitle">{props.title}</h4> : ""}
      <ul className="menuList">
        {props.links.map((link) => {
          return (
            <li key={link._key}>
              {
                // Test for external link
                // This assumes that any internal link
                // will start with exactly one slash, and that anything else is external.
                /^\/(?!\/)/.test(link.href) ? (
                  <Link to={link.href}>{link.text}</Link>
                ) : (
                  <a href={link.href}>{link.text}</a>
                )
              }
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuList;
