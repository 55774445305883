import React from "react";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import LatexRenderer from "./Latex";
import Link from "./link";
import EmbeddedAudio from "./embeddedAudio";
import SoundcloudEmbed from "./soundcloudEmbed";

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const serializers = (clientConfig) => {
  return {
    marks: {
      // Overwriting the existing LinkSerializer with our own
      link: ({ children, mark }) => {
        return (
          <Link href={mark.href} newTab={mark.newTab}>
            {children}
          </Link>
        );
      },
    },
    types: {
      authorReference: AuthorReference,
      mainImage: ({ node }) => {
        return <MainImage mainImage={node} clientConfig={clientConfig} />;
      },
      videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls />,
      instagram: ({ node }) => {
        if (!node.url) return null;
        return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
      },
      math: ({ node, isInline = false }) => (
        <LatexRenderer isInline={isInline} latex={node.latex} />
      ),
      pdfDocument: ({ node }) => {
        const pdfLink =
          node.pdfFile && node.pdfFile.asset && node.pdfFile.asset.url
            ? node.pdfFile.asset.url
            : "";
        const title = node.title ?? "";

        if (!pdfLink || !title) return null;
        return (
          <p>
            <a href={pdfLink}>{title}</a>
          </p>
        );
      },
      embeddedAudio: ({ node }) => {
        return (
          <EmbeddedAudio
            title={node.title}
            audioFile={node.audioFile}
            captionsFile={node.captionsFile}
          />
        );
      },
      soundcloudEmbed: ({ node }) => {
        return (
          <SoundcloudEmbed title={node.title} soundcloudEmbedCode={node.soundcloudEmbedCode} />
        );
      },
    },
  };
};

export default serializers;
