import React from "react";
import PropTypes from "prop-types";

const FacebookLink = (props) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.facebook.com/${props.profileName}`}
    >
      <svg
        style={{ height: "20px" }}
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="facebook-square"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="svg-inline facebook-square"
      >
        <path
          fill="#ffffff"
          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
        ></path>
      </svg>
      <span className="sr-only">Our Facebook page</span>
    </a>
  );
};

const TwitterLink = (props) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${props.profileName}`}>
      <svg
        style={{ height: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        aria-hidden="true"
        focusable="false"
        data-icon="x-twitter"
        role="img"
      >
        <path
          fill="#fff"
          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
        ></path>
      </svg>
      <span className="sr-only">Our X page</span>
    </a>
  );
};

const LinkedInLink = (props) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={props.url}>
      <svg
        style={{ height: "20px" }}
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="linkedin"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="svg-inline fa-linkedin"
      >
        <path
          fill="#ffffff"
          d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
          className=""
        ></path>
      </svg>
      <span className="sr-only">Our LinkedIn page</span>
    </a>
  );
};

const YouTubeLink = (props) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.youtube.com/user/${props.profileName}`}
    >
      <svg
        style={{ height: "20px" }}
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="youtube"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        className="svg-inline fa-youtube"
      >
        <path
          fill="currentColor"
          d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
        ></path>
      </svg>
      <span className="sr-only">Our YouTube channel</span>
    </a>
  );
};

const Social = (props) => {
  const socials = props.socials || { facebook: "", twitter: "", linkedin: "", youtube: "" };
  return (
    <div className="flex socialCont">
      {socials.facebook ? <FacebookLink profileName={socials.facebook} /> : ""}
      {socials.twitter ? <TwitterLink profileName={socials.twitter} /> : ""}
      {socials.linkedin ? <LinkedInLink url={socials.linkedin} /> : ""}
      {socials.youtube ? <YouTubeLink profileName={socials.youtube} /> : ""}
    </div>
  );
};

export default Social;

Social.propTypes = {
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  youtube: PropTypes.string,
};
