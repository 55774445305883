import React from "react";

const Link = ({ href, newTab, children }) => {
  // If newTab is true, then put together target:blank and rel:noopener etc.
  const target = newTab ? { target: "_blank", rel: "noopener noreferrer" } : false;

  // Add # as a fallback incase there is no url given for some reason.
  let url = href ?? "#";

  return (
    <a href={url} {...target}>
      {children}
    </a>
  );
};

export default Link;
