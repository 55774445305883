import React from "react";
import PropTypes from "prop-types";

const Video = ({ youtubeVideo, youtubeVideoTitle }) => (
  <div className="video">
    <iframe
      src={youtubeVideo}
      title={youtubeVideoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
);
export default function sbVideo(props) {
  return <Video {...props} />;
}
Video.propTypes = {
  youtubeVideo: PropTypes.string,
  youtubeVideoTitle: PropTypes.string,
};
