import React from "react";

const AudioPlayer = ({ title, audioFile, captionsFile }) => {
  // Example:  https://2001archive.files.wordpress.com/2015/07/cantdo.wav
  return (
    <div>
      {audioFile && (
        // This rule is triggered dispite the fact we provide the caption track element, so I'm disabling the rule for this line.
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={audioFile} title={title}>
          {captionsFile && <track default kind="captions" srclang="en" src={captionsFile} />}
          Your browser does not support embedded audio.
        </audio>
      )}
    </div>
  );
};

export default AudioPlayer;
