import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

const MainImage = ({ mainImage, clientConfig }) => {
  const imgUrl = mainImage && imageUrlFor(buildImageObj(mainImage), clientConfig).url();

  const fluidProps = getFluidGatsbyImage(
    mainImage.asset._id,
    { maxWidth: 992 },
    clientConfig.sanity
  );

  return imgUrl && <img src={fluidProps.src} alt={mainImage.alt || ""} />;
};

export default MainImage;
