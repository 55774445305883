import React from "react";

import MenuList from "./menu-list";

const FooterBlock = (props) => {
  const footerData = props.footerData;
  if (footerData && footerData.toggleFooter) {
    return (
      <>
        {footerData.menuLists.length > 0 ? (
          <div id="footerNav">
            <div className="container mx-auto">
              <div className="flex justify-around">
                {footerData.menuLists.map((list) => {
                  return (
                    <MenuList title={list.menuTitle} links={list.footerLink} key={list._key} />
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <footer>
          <p>
            &copy; UEA. All rights reserved. University of East Anglia, Norwich Research Park,
            Norwich, Norfolk, NR4 7TJ, UK | +44 (0) 1603 456161
          </p>
          <ul className="footerLinks">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.uea.ac.uk/about/university-information/statutory-and-legal/website"
              >
                Accessibility
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.uea.ac.uk/about/university-information/statutory-legal-policies"
              >
                Legal Statements
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.uea.ac.uk/about/university-information/statutory-legal-policies/modern-slavery-statement"
              >
                Statement on Modern Slavery
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.uea.ac.uk/about/university-information/statutory-and-legal/website"
              >
                Cookie Policy
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.uea.ac.uk/about/university-information/statutory-and-legal/data-protection"
              >
                Data Protection
              </a>
            </li>
          </ul>
        </footer>
      </>
    );
  } else {
    return "";
  }
};

export default FooterBlock;
