import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import { Layout } from "../../../../../shared_ui";
import clientConfig from "../../client-config";

const query = graphql`
  query LayoutQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      ...MastheadConfig
      siteLogo {
        alt
        asset {
          url
          _id
        }
      }
      headerImage {
        alt
        asset {
          url
        }
        crop {
          top
          bottom
          left
          right
        }
        hotspot {
          x
          y
        }
      }
      sidebar {
        youtubeVideo
        youtubeVideoTitle
        twitterFeed
        twitterFeedTitle
        toggleSidebar
      }
      footer {
        toggleFooter
        menuLists {
          _key
          menuTitle
          footerLink {
            _key
            text
            href
          }
        }
      }
      socials {
        facebook
        twitter
        linkedin
        youtube
      }
    }
    recentBlogs: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 2) {
      nodes {
        title
        _rawExcerpt
        slug {
          current
        }
      }
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(true);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  const { textWhite = false } = props;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            siteLogo={data.site.siteLogo}
            headerImage={data.site.headerImage}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            textWhite={textWhite}
            sidebarData={data.site.sidebar}
            recentBlogs={data.recentBlogs}
            footerData={data.site.footer}
            mastheadConfig={data.site.masthead}
            clientConfig={clientConfig}
          />
        );
      }}
    />
  );
}

export default LayoutContainer;
