import React from "react";
import Video from "./video";
import { TwitterTimeline } from "./twitterembed";

const Sidebar = ({ sidebarData, recentBlogs }) => {
  return (
    <div id="sidebar">
      <Video
        youtubeVideo={sidebarData && sidebarData.youtubeVideo}
        youtubeVideoTitle={sidebarData && sidebarData.youtubeVideoTitle}
      />
      <hr />
      <TwitterTimeline
        twitterUrl={sidebarData && sidebarData.twitterFeed}
        timelineTitle={sidebarData && sidebarData.twitterFeedTitle}
      />
    </div>
  );
};

export default Sidebar;
