import React from "react";

import Logo from "../images/uea-logo.png";
import Social from "./social";
import MobileNav from "./mobileNav";
import { Auth } from "aws-amplify";
import { navigate } from "@reach/router";
import Amplify from "aws-amplify";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

import { SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";

class MastHead extends React.Component {
  state = { mobileNavOpen: false, auth: false };

  openNav = () => {
    this.setState({ mobileNavOpen: true });
  };
  toggleMobileNav = () => {
    this.setState((prevState) => {
      return {
        mobileNavOpen: !prevState.mobileNavOpen,
      };
    });
  };
  // If tabbing along, now is the time to open the menu
  handleMenuTab = (e) => {
    if (!e.shiftKey && e.keyCode === 9) {
      // Not interested if tabbing backwards
      // i.e. shift is held
      this.openNav();
    }
  };

  // If tabbing backwards, now is the time to close the menu
  handleCloseButtonTab = (e) => {
    if (e.shiftKey && e.keyCode === 9) {
      // ONLY interested if tabbing backwards
      this.setState({ mobileNavOpen: false });
    }
  };

  isUserAuthenticated = async () => {
    if ("aws_project_region" in this.props.awsConfig) {
      return await Auth.currentAuthenticatedUser()
        .then((data) => {
          this.setState({ auth: data });
          return true;
        })
        .catch(() => {
          return false;
        });
    } else {
      return false;
    }
  };

  signOut = () => {
    Auth.signOut()
      .then(() => {
        navigate("/");
        this.setState({ auth: false });
      })
      .catch((err) => console.log(err));
  };

  componentDidMount = () => {
    Amplify.configure(this.props.awsConfig);

    this.isUserAuthenticated();

    // closes menu when open and esc is pressed
    window.addEventListener("keydown", (e) => {
      if (this.state.mobileNavOpen) {
        // 27 = escape
        if (e.key === "Escape" || e.key === "Esc" || e.key === 27) {
          this.toggleMobileNav();
        }
      }
    });
  };

  mastheadLogo = (logoObject, link) => {
    // Check we have all the info we need
    // for a custom logo. If not, we do a
    // default UEA one.
    const containsAllRequiredInfo =
      // !! converts an expression into a boolean
      !!(logoObject && logoObject.asset && logoObject.asset.url && logoObject.alt && link);

    if (containsAllRequiredInfo) {
      const fluidProps = getFluidGatsbyImage(
        logoObject.asset._id,
        { maxWidth: 300 },
        this.props.clientConfig.sanity
      );

      return (
        <a
          href={link}
          title="Find out more about our organisation (link opens in a new tab)"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={fluidProps.src}
            alt={logoObject.alt}
            style={{ height: "26px", width: "auto", marginBottom: 0 }}
          />
        </a>
      );
    } else {
      return (
        <a
          href="https://www.uea.ac.uk"
          title="Open UEA site's homepage in a new tab"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Logo}
            alt="UEA Logo"
            style={{ height: "26px", width: "auto", marginBottom: 0 }}
          />
        </a>
      );
    }
  };

  render() {
    const navbarList = this.props.mobileNavList;

    const mastheadLogo = this.props.mastheadConfig && this.props.mastheadConfig.logo;
    const mastheadLogoLink = this.props.mastheadConfig && this.props.mastheadConfig.link;

    // renderMenu is a boolean here based on the result of this statement
    // If there's nothing to go in the menu, no need to render it
    const renderMenu = !!(this.state.auth !== false || navbarList);

    return (
      <div className="mastHead">
        <SkipNavLink style={{ color: "black" }} />
        <div className="container mx-auto">
          <div className="row">
            {this.mastheadLogo(mastheadLogo, mastheadLogoLink)}
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.auth !== false && (
                <p className="login-status-short">You are logged in.</p>
              )}
              <Social socials={this.props.socials} />
              {
                // Don't render the button unless it's needed for
                // navigation or sign out / change password
                renderMenu ? (
                  <button
                    className="menu-btn"
                    onClick={this.toggleMobileNav}
                    onKeyDown={this.handleMenuTab}
                    aria-haspopup="true"
                    aria-expanded={this.state.mobileNavOpen ? "true" : "false"}
                  >
                    Menu
                    <span>
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </span>
                  </button>
                ) : (
                  ""
                )
              }
            </div>
            {renderMenu ? (
              <div
                className="mobile-nav-overlay"
                style={{ width: this.state.mobileNavOpen ? "80%" : "0", maxWidth: "400px" }}
              >
                {/* Close button */}
                <button
                  className="menu-close-btn"
                  onClick={this.toggleMobileNav}
                  onKeyDown={this.handleCloseButtonTab}
                >
                  x
                </button>
                <div className="mobile-nav-content">
                  <MobileNav
                    navbarList={navbarList}
                    toggleMobileNav={this.toggleMobileNav}
                    auth={this.state.auth}
                    signOut={this.signOut}
                    openNav={this.openNav}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MastHead;
