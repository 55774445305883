import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

import { SkipNavContent } from "@reach/skip-nav";

const Banner = ({ siteTitle, headerImage, siteLogo, clientConfig }) => {
  const backgroundImgUrl =
    headerImage &&
    headerImage.asset &&
    headerImage.asset.url +
      `?w=1920&h=300` +
      // Add hotspot info only if the information is there
      (headerImage.hotspot != null
        ? `&fit=crop&fp-y=${headerImage.hotspot.y}&fp-x=${headerImage.hotspot.x}`
        : "");

  const siteLogoUrl = siteLogo && siteLogo.asset && siteLogo.asset.url;
  const siteLogoAlt = siteLogo && siteLogo.alt;

  const fluidProps = siteLogoUrl
    ? getFluidGatsbyImage(siteLogo.asset._id, { maxWidth: 200 }, clientConfig.sanity)
    : "";

  return (
    <div
      id="banner"
      role="banner"
      style={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.1)), url(${backgroundImgUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "300px",
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
    >
      <div className="container flex mx-auto">
        <div>
          <Link to="/" title="Link to homepage">
            {siteLogoUrl ? (
              <img
                src={fluidProps.src}
                alt={siteLogoAlt}
                className="pl-8"
                style={{
                  maxHeight: "72px",
                  width: "auto",
                  marginBottom: 0,
                }}
              />
            ) : (
              <p className="text-4xl md:text-5xl lg:text-6xl font-medium mb-0 mx-3">{siteTitle}</p>
            )}
          </Link>
        </div>
      </div>
      <SkipNavContent />
    </div>
  );
};

Banner.propTypes = {
  siteTitle: PropTypes.string,
  headerImage: PropTypes.object,
  siteLogo: PropTypes.object,
};

export default Banner;
