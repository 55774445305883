import {
  // format,
  isFuture,
} from "date-fns";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getBlogUrl(slug) {
  return `/blog/${slug.current || slug}/`;
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset?._ref || source.asset?._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

/**
 * This is a function that accepts a background colour and works out whether white text
 * or black text would display with a higher against it. Does not account for Alpha.
 *
 * @param {string} bgColor - The background colour in hexcode format. e.g. #12ab45
 * @param {string} lightColor - A light color of text. Defaults to #FFFFFF
 * @param {string} darkColor - A dark color of text. Defaults to #000000
 * @return {string} Either the lightColor or the darkColor.
 *
 * @example
 *     pickTextColorBasedOnBgColor('#556788', '#FFFFFF', '#000000')
 */
export function pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
  // Thanks https://stackoverflow.com/a/41491220/10228954
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1) : bgColor;
  lightColor = typeof lightColor !== "undefined" ? lightColor : "#FFFFFF";
  darkColor = typeof darkColor !== "undefined" ? darkColor : "#000000";
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

/**
 * Accepts a perctange and returns that percentage in hex. Useful for converting Sanity
 * alpha values to hex for appending to hexcode colours. Will round floats.
 *
 * @param {float|int} percent - A number
 * @return {string} Two digit hexcode.
 *
 * @example
 *      var alphaPercent = 0.12 * 100;
 *      var almostTransparentWhite = "#FFFFFF" + percentToHex(alphaPercent);
 */
export function percentToHex(percent) {
  var decimalValue = Math.round((percent * 255) / 100);
  var hexValue;

  if (percent < 7) {
    hexValue = "0" + decimalValue.toString(16).toUpperCase();
  } else {
    hexValue = decimalValue.toString(16).toUpperCase();
  }

  return hexValue;
}

export function internalLink(target) {
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    return true;
  } else {
    return false;
  }
}
